<svelte:options tag={'lottery-program-wof-private-outcomes'} />

<script lang="ts">
  import type { WheelOfFortunePartition } from "./api";
  import { api } from "./business";
  import { onMountMessageLifeCycle } from "./message";
  import type { LotteryProgramForPlayer } from "./types.business";
  import { _ } from './i18n';
  import './private.loader.svelte'
  import { Lang } from "./types";
  import { setClientStyling } from "./widget";

  // properties
  export let lang: Lang = Lang.en
  export let endpoint: string = ''
  export let session: string = ''
  export let clientstyling:string = ''
  export let id: string = ''
  
  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);

  interface RenderReady {
    id: string,
  }

  interface PartitionsR extends RenderReady {
    partitions: WheelOfFortunePartition[]
  }
  
  interface outcomesItemR extends RenderReady {
    outcomes: any[]
    isLoading: boolean
  }

  let bonuses: LotteryProgramForPlayer[] 
  let ids: string[] = []
  let partitionsList: PartitionsR[] = []
  let outcomesList: outcomesItemR[] = [];
  
  const getName = (objectI18n: any) => objectI18n?.name['*']

  const getPrize = (outcome, id) => {
    const partitions = partitionsList.filter(partitionsItem => partitionsItem.id === id)[0].partitions
    const index = outcome.draw.result?.wheelOfFortunePartitionIndex
    
    return !index ? 'Loss' : getName(partitions[index])
  }

  const eventShowOutcome = async () => {

    outcomesList = []
    ids.map(id => {
      outcomesList.push({
        id,
        outcomes: [],
        isLoading: true,
      })
    })

    ids.map(async id => {
      const result = await api.outcome(endpoint, session, id)
      const outcomes = result.items
      outcomesList = outcomesList.map(o => {
        if(o.id === id){
          return {
            ...o,
            outcomes,
            isLoading: false,
          }
        }else{
          return o
        }
      })
    })
  }

  const updateData = () => {
    ids = 
      bonuses.map(l => l.program.id)

    partitionsList = 
      bonuses.map(bonus => ({
        id: bonus.program.id,
        partitions: bonus.program.wheelOfFortune.partitions,
      }))
  }

  $: bonuses && updateData()

  onMountMessageLifeCycle({
    'wof-private-bonuses-outcomes': (data) => {
      bonuses = data.bonuses
    },
    'wof-private-message-outcomes': () => {
      eventShowOutcome()
    }
  })

</script>

<div class="Outcomes" bind:this={rootContainer}>
  <div class="TableContainer">
    <table>
      {#each outcomesList as outcomesItem}
        {#if outcomesItem.isLoading}
          <wof-loader />
        {:else}
          {#if !outcomesItem.outcomes || outcomesItem.outcomes.length === 0}
            <div class="OutcomeNoContent">{$_('wof.NoHistories')}</div>
          {:else}
          <div>
            <tr>{getName(bonuses.filter(l => l.program.id === outcomesItem.id)[0].program)}</tr>
          </div>
          <thead>
            <tr>
              <th>{$_('wof.Rewards')}</th>
              <th>{$_('wof.Status')}</th>
              <th>{$_('wof.Time')}</th>
            </tr>
          </thead>
          <tbody style={outcomesItem.outcomes.length <= 5 ? 'overflow: hidden;' : ''}>
            {#each outcomesItem.outcomes as outcome}
            <tr>
              <td>{@html getPrize(outcome, outcomesItem.id)}</td>
              <td>{outcome.draw.state}</td>
              <td>{(new Date(outcome.draw.time).toLocaleString(undefined, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }))}</td>
            </tr>
            {/each}
          </tbody>
          {/if}
        {/if}
      {/each}
    </table>
  </div>
</div>

<style lang="scss">

  :host {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    width: 100%;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  .Outcomes {
    
    width: 80%;
    margin: 30px auto;
  }

  .OutcomeTitle {
    color: #FFF;
    font-weight: 700;
    padding: 20px 0;
  }
  .OutcomeNoContent {
    color: #FFF;
    font-weight: 700;
    padding: 20px 20px;
  }

  // .TableContainer tbody {
  //   max-height: 300px;
  //   overflow-y: auto;
  // }

  table {
    
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    tr {
      margin-top: 10px;
      line-height: normal;
    }

  }

  table tbody {
    display: block;
    max-height: 300px;
    overflow-y: scroll;
    margin: 30px 0;
  }

  table thead, table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

</style>