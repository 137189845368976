import type { 
  LotteryProgramDraw,
  LotteryProgramILotteryProgramForPlayer, 
  LotteryProgramIQueryLotteryProgramForPlayerResult 
} from "./api";


export type LotteryProgramForPlayer = LotteryProgramILotteryProgramForPlayer

export enum CurrentInfo {
  ActiveTickets   = 'ActiveTickets',
  ImplicitTickets = 'ImplicitTickets',
  RemainingTimes  = 'RemainingTimes',
}

export type ApiLotteriesResult = LotteryProgramIQueryLotteryProgramForPlayerResult

export interface ApiDrawResult {
  item?: LotteryProgramDraw;
}