<svelte:options tag={'lottery-program-wof-private-message-panel'} />

<script lang="ts">
  // @ts-ignore
  import giftSvg from './images/gift.svg'
  import { onMountMessageLifeCycle, _postMessage } from './message';
  import { _ } from './i18n';
  import { setClientStyling } from './widget';

  export let r = 0
  export let size = 0
  export let id: string = undefined
  export let clientstyling:string = ''
  export let giftimagesrc: string = ''
  
  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);

  let topOffset: number = 0

  let isShown: boolean = false
  let mode: string = ''
  let modeValue: any
  let isOptionsReady: boolean = false

  onMountMessageLifeCycle({
    'wof-private-options-ready': (data) => {
      if(data.id !== id) return;
      isOptionsReady = true
    },
    'wof-private-message-open': (data) => {
      if(data.id !== id) return;

      mode = data.mode
      modeValue = data.modeValue

      isShown = true
    }
  })

  const _postMessageScoped = (props) => _postMessage({ ...props, id })
  
  const eventButton = () => {
    switch(mode){
      case 'timeout':
        isShown = false
        _postMessageScoped({ type: 'wof-private-message-retry' })
        break
      default:
        isShown = false
        _postMessageScoped({ type: 'wof-private-message-close' })
        break
    }
  }

  const getTimeString = (_d) => {
    const d: Date = new Date(_d)
    
    return [
      [
        d.toLocaleString('en-us', {year: 'numeric'}),
        d.toLocaleString('en-us', {month: '2-digit'}),
        d.toLocaleString('en-us', {day: '2-digit'}),
      ].join('-'),
      d.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric', second: 'numeric' }),
    ].join(' ')
  }

  const errorCodeMap = {
    ErrorNetwork: 'N0001',
    ErrorJSON: 'N0002',
    Retry: 'N0003',
    Timeout: 'N0004',
  }

  const setObserver = () => {
    const resizeObserver = new ResizeObserver(entries => {
      entries.map(entry => {
        if(entry.target === rootContainer) {
          topOffset = entry.target.clientHeight / 2
        }
      })
    })
    resizeObserver.observe(rootContainer)
  }

  $: rootContainer && setObserver()

  $: width = r * 2
  $: left = size / 2 - width / 2
  $: top = size / 2 - topOffset

  $: startTime = modeValue?.startTime && getTimeString(modeValue?.startTime)

  $: prizeSrc = modeValue?.prizeSrc || giftimagesrc || giftSvg

  const handlerTC = () => _postMessage({ type: "OnTCClicked", WofProgramID: id })

  let holderTC: HTMLElement
  const bindEventTC = () => {
    holderTC.querySelector('a').addEventListener('click', handlerTC)
  }

  $: holderTC && bindEventTC()
</script>


{#if isShown && isOptionsReady}
<div 
  bind:this={rootContainer}
  class="MessagePanel" 
  style={[
    `top: ${top}px`,
    `left: ${left}px`,
    `width: ${width}px`,
    isShown ? 'opacity: 1' : '',
  ].join(';')}
>

  {#if mode === 'normal' || mode === 'init-failed' || mode === 'timeout'}
  <p>{$_(`wof.${modeValue}`)}</p>
  {/if}

  {#if mode === 'show-next'}
  <p>{@html $_('wof.ShowNext', {values: { startTime }})}</p>
  {/if}

  {#if mode === 'NoNext'}
  <p bind:this={holderTC}>{@html $_(`wof.NoNext`)}</p>
  {/if}

  {#if mode === 'gift'}
  <p>
    <img src={prizeSrc} alt="" class="GiftImage">
  </p>
    {#if modeValue.prizeText}
      {@html modeValue.prizeText}
    {:else}
      {@html $_('wof.Congratulation', {values: { prize: modeValue.prize }})}
    {/if}
  <p>

  </p>
  {/if}

  {#if mode === 'nogift'}
  <p>
    <img src={modeValue.image} alt="" class="GiftImage">
  </p>
  <p>{@html $_('wof.Loss')}</p>
  {/if}

  
  {#if errorCodeMap[modeValue]}
  <p class="ErrorCode">
    Errorcode: {errorCodeMap[modeValue]}
  </p>
  {/if}

  {#if !['init-failed', 'show-next', 'NoNext'].includes(mode)}
  <div>
    <button
      class="MessagePanelButton"
      on:click={eventButton}
    >
      {$_('wof.OK')}
    </button>
  </div>
  {/if}
</div>
{/if}

<style lang="scss">

  :host {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    position: absolute;
    width: 100%;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }
  $outlineColor: rgb(150, 54, 88);
  $outlineWidth: 2px;

  .GiftImage {
    max-width: 80%;
    height: 100px;
  }

  .MessagePanel {
    position: absolute;
    background-color: #FFFFFF;
    color: #000000;
    padding: 15px 30px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    opacity: .3;
    
    // border: 3px solid rgb(80, 80, 80);
    box-shadow: 0 0 1px #a1a1a1;

    // transform: translateX(-50%);
    transition: margin-top opacity .3s ease-out;
  }

  .MessagePanelButton { 
    padding: 4px 24px;
    margin-top: 40px;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #FF0000;
    border-radius: 5px;
    font-weight: 700;
    border: 0;
    box-shadow: 0 0 3px #ff0000;
    cursor: pointer;
  }

  .ErrorCode {
    color: #b50000;
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
  }
</style>