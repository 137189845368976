<svelte:options tag={'lottery-program-wof-private-item'} />

<script lang="ts">
  import './private.message.svelte'
  import './private.tabs.svelte'
  import './private.outcomes.svelte'
  import './private.item.svg.svelte'

  // types
  import { ContentDirection, Lang } from './types';
  import { getCurrentInfo, isBonusInvalid } from './business';
  import { api } from './business';
  import { onMountMessageLifeCycle, _postMessage } from './message';
  import { CurrentInfo, LotteryProgramForPlayer } from './types.business';
  import { _ } from './i18n';
  import { setClientStyling } from './widget';

  // properties common
  export let lang: Lang = Lang.en
  export let endpoint: string = ''
  export let session: string = ''
  export let clientstyling:string = ''
  export let currentinfo: string = CurrentInfo.RemainingTimes
  export let giftimagesrc: string = ''
  export let contentdirection: keyof typeof ContentDirection = 'anticlockwise'
  export let size: string = '480'
  
  // properties
  export let id: string = ''

  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);

  $: commonProps = {
    endpoint,
    lang,
    session,
    clientstyling,
  }

  let bonus: LotteryProgramForPlayer
  let bonusNew: LotteryProgramForPlayer
  let currentInfoKeys: CurrentInfo[]
  let isOptionsReady: boolean = false
  let checkableValid: boolean = true

  const refetcher = async () => {
    const LotteryProgramsForPlayer = await api.lotteries(endpoint, session)
    return LotteryProgramsForPlayer.filter(l => l.program.id === id)[0]
  }
  
  onMountMessageLifeCycle({
    'wof-private-options-ready': (data) => {
      if(data.id !== id) return;
      isOptionsReady = true
    },
    'wof-private-bonuses': (data) => {
      const bonusFound = data.bonuses.find(l => l.program.id === id)
      if(bonusFound){
        bonus = bonusFound
      }
    },
    'wof-private-message-spin-after': (data) => {
      if(data.id !== id) return;
      if(!bonusNew) return;
      bonus = bonusNew
      _postMessage({type: 'wof-private-bonus', bonus: bonusNew, id })
    },

    'wof-private-message-spin-before': async (data) => {
      if(data.id !== id) return;
      checkableValid = false
      bonusNew = await refetcher()
    },

    'wof-private-message-close': (data) => {
      if(data.id !== id) return;
      _postMessage({type: 'wof-private-checking', bonus, id })
      setTimeout(() => {
        checkableValid = true
      }, 1000);
    },
  })

  // reactives
  $: _size = Number(size) || 480
  $: radius = 0.62 * _size / 2;
  
  // @ts-ignore
  $: currentInfoKeys = currentinfo ? currentinfo.split(',') : [CurrentInfo.RemainingTimes]
</script>

<div 
  class="LotteryItem" 
  class:Invalid={isBonusInvalid(bonus) && checkableValid}
  bind:this={rootContainer}
>
  <lottery-program-wof-private-item-svg {...{ 
    ...commonProps,
    id,
    size: _size,
    radius,
    contentdirection,
  }} />

  <lottery-program-wof-private-message-panel {...{
    ...commonProps,
    id,
    size: _size,
    r: radius,
    giftimagesrc,
  }} />
  
  {#if bonus?.current && isOptionsReady}
  <div class="Current">
    {#each currentInfoKeys as CurrentInfoKey}
      <p class={CurrentInfoKey}>
        {$_(`wof.${CurrentInfoKey}`)}: {getCurrentInfo(bonus, CurrentInfoKey)}
      </p>
    {/each}
  </div>
  {/if}

</div>

<style lang="scss">
  @mixin FortuneContainer {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  lottery-program-wof-private-message-panel {
    position: absolute;
    top: 0;
  }



  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  $outlineColor: rgb(150, 54, 88);
  $outlineWidth: 2px;

  .LotteryItem {
    @include FortuneContainer;
    margin: 20px 0;

    position: relative;
    max-width: 600px;
    justify-content: space-around;
    min-height: 200px;

    &.Invalid { 
      display: none;
    }
  }

  .Current {
    color: #FFFFFF;
  }
</style>