import { easing, easingGrad, params, SpinStep, Easing, easingGradX } from "./class.spinner.util";

export class Spinner {

  deg: number = 0
  fps: number;
  // spinable: boolean = true
  degTarget: number = undefined
  step: SpinStep = undefined
  tick
  cb
  easingType: Easing = Easing.cubic
  shouldHalt

  param = params[2]

  constructor(inits){
    this.tick = inits.tick
  }

  public launch(){
    this.shouldHalt = undefined
    this.step = SpinStep.launch
    this.ticker()
  }

  public halt = (deg, cb = () => {}) => {
    this.shouldHalt = () => {
      this.step = SpinStep.halt
      this.cb = () => {
        cb()
        this.setDeg(deg, 0)
      }
      this.degTarget = deg + 360 * this.param.haltTurns
      this.continueRepeat()
    }
  }

  private continueRepeat = () => setTimeout(() => this.ticker(), 30)
  private ticker = () => {
    
    const linkSpeed = this.param.loopSpeed

    const easingDuration = (deg: number) => 
      33 * easingGrad[this.easingType] * deg / this.param.loopSpeed

    const animations = {
      launch: () => {
        const degTar = 360 * this.param.launchTurns - this.deg
        const t = easingDuration(degTar)
        
        this.animateF(t, this.deg, degTar, easing.in[this.easingType], easingGradX.in[this.easingType])
      },
      halt: () => {
        const t = easingDuration(this.degTarget)
        this.animateF(t, 0, this.degTarget, easing.out[this.easingType], easingGradX.out[this.easingType])
      },
      loop: () => {
        this.setDeg((this.deg + linkSpeed) % 360, easingGrad[this.easingType])
        this.continueRepeat()
      }
    }

    const stepSwitcher = {
      [SpinStep.launch]: () => {
        this.cb = () => {
          this.step = SpinStep.loop
          this.setDeg(linkSpeed, easingGrad[this.easingType])
          this.continueRepeat()
        }
        animations.launch()
      },
      [SpinStep.loop]: () => {
        if(this.shouldHalt && this.deg === 0){
          this.shouldHalt()
        }else{
          animations.loop()
        }
      },
      [SpinStep.halt]: () => {
        if(this.deg === 360 - linkSpeed){
          animations.halt()
        }else{
          animations.loop()
        }
      }
    }

    stepSwitcher[this.step]()
    
    // switch(this.step){
    //   case SpinStep.launch:
        
    //     break
    //   case SpinStep.loop:
        
    //     if(this.shouldHalt && this.deg === 0){
    //       this.shouldHalt()
    //     }else{
          
    //       this.setDeg((this.deg + linkSpeed) % 360, easingGrad[this.easingType])
    //       this.continueRepeat()
    //     }
    //     break
    //   case SpinStep.halt:
        
    //     if(this.deg === 360 - linkSpeed){
          
    //       const t = 30 * easingGrad[this.easingType] * this.degTarget / this.param.loopSpeed
    //       this.animate(t, 0, this.degTarget, easing.out[this.easingType], easingGradX.out[this.easingType])
    //     }else{
    //       this.setDeg((this.deg + linkSpeed) % 360, easingGrad[this.easingType])
    //       this.continueRepeat()
    //     }
    //     break
    // }
  }

  private setDeg = (deg, speed) => {
    this.deg = deg
    this.tick(deg, speed)
  }

  animateF = (duration: number, startAngle, target: number, easing, _easingGrad) => {
    let start = null

    const step = () => {
      let timestamp = new Date().getTime()
      if(!start){
        start = timestamp
      }
      const progress = timestamp - start
      
      const translation = target * easing(progress / duration) + startAngle;
      const speedCur = Math.abs(_easingGrad(progress / duration))
      //const speedRealCur = target / duration * Math.abs(_easingGrad(progress / duration)) * 33
      
      this.setDeg(translation, speedCur)

      if (progress < duration) {
        setTimeout(() => step(), 30)
      }else{
        this.cb()
      }
    }
    step()
  }

  animate = (duration: number, startAngle, target: number, easing, _easingGrad) => {

    let animationId = null;
    window.cancelAnimationFrame(animationId);
    let start = null;

    const step = (timestamp) => {
      
      if (!start) {
        start = timestamp;
      }
      
      const progress = timestamp - start;
      const translation = target * easing(progress / duration) + startAngle;
      const speedCur = Math.abs(_easingGrad(progress / duration))
      
      this.setDeg(translation, speedCur)
      
      if (progress < duration) {
        animationId = window.requestAnimationFrame(step);
      }else{
        this.cb()
      }
    }
    
    animationId = window.requestAnimationFrame(step);
  }
}