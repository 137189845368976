import { addNewMessages } from "./i18n";
import { fetchBase, fetcher } from "./util";

export const setTranslation = (translations) => {
  Object.keys(translations).forEach((item:any):void => {
    addNewMessages(item, translations[item]);
  });
}

export const setTranslationUrl = async (translationurl) => {

  const res = await fetchBase(translationurl)

  setTranslation(res)
}

export const setClientStyling = (container, clientstyling) => {
  let sheet = document.createElement('style');
  sheet.innerHTML = clientstyling;
  container.appendChild(sheet);
}

export const setClientStylingURL = async (clientstylingurl, callback) => {
  const res = await fetch(clientstylingurl)
  const clientstyling = await res.text()
  callback(clientstyling)
}