export const convertDegToArc = (deg: number) => deg * Math.PI / 180
export const convertArcToDeg = (arc: number) => arc * 180 / Math.PI % 360

export const getArcDelta = (n: number) => 2 * Math.PI / n

export const findIndex = (deg: number, n: number) =>
  Math.floor(
    (360 - deg % 360) / convertArcToDeg(getArcDelta(n))
  )

export const findDeg = (index: number, n: number) => {
  const degDelta = convertArcToDeg(getArcDelta(n))
  return (index) * degDelta
}

export const createSVGElement = (tag: string) => {
  const xmlns = "http://www.w3.org/2000/svg";
  return document.createElementNS(xmlns, tag);
}

export const ratioGoldenMean = (times: number) => Math.pow(0.618, times)


const randomDirection = () => Math.random() > 0.5 ? 1 : -1

export const randomInSection = (number: number) => {
  return 360 / number / 2 * (1 - 0.2) * Math.random() * randomDirection()
}