export const imageLoader = (src: string) => {
  return new Promise((resolve, reject) => {
    let img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

export const clamp = (num: number, min: number, max?: number) => {
  if(max){
    return Math.min(Math.max(num, min), max)
  }else{
    return Math.max(num, min)
  }
}

export const imageLoaderSvg = (src: string) => {
  return new Promise((resolve, reject) => {
    let img = document.createElementNS("http://www.w3.org/2000/svg", "image")
    img.onload = () => resolve(img)
    img.onerror = () => resolve(img) //reject
    img.href.baseVal = src
  })
}

const cvtCaseFromCamelToKebab = (text: string) => 
  text
    .split(/(?=[A-Z])/)
    .map(s => s.toLocaleLowerCase())
    .join('-')

export const setProps = <T extends Element>(element: T, props: any) => {
  Object.keys(props).map(_key => {
    element.setAttribute(cvtCaseFromCamelToKebab(_key), props[_key])
  })
}

export const setPropsSVG = <T extends SVGElement>(element: T, props: any) => {
  Object.keys(props).map(_key => {
    element.setAttributeNS("http://www.w3.org/2000/svg", cvtCaseFromCamelToKebab(_key), props[_key])
  })
}

export const delay = (time: number) => new Promise(res => setTimeout(res, time * 1000));

type MapObjectValueToTuple<T extends Object> = T[keyof T][]

export const mapObjectValueToTuple = 
  <T>(object: T): MapObjectValueToTuple<T> => 
    Object.keys(object).map(key => object[key])

interface fetcherArgs {
  path: string, 
  params?: Object, 
  options?: RequestInit
}

export const fetcher = async (config: fetcherArgs) => {

  const { path, params = {}, options = {} } = config

  const url = new URL(path)

  Object.keys(params).map(_key => {
    url.searchParams.append(_key, params[_key])
  })

  return await fetchBase(url, options)
}


export const fetchBase = async (input: RequestInfo | URL, init: RequestInit = {}): Promise<Response> => {
  
  let res: any 
  try {
    res = await fetch(input, init)
    res = await res.text()
    res = JSON.parse(res)
  } catch (e) {
    throw new Error(e.message)
  }

  return res
}

export const isSafari = () => {
  var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari;
}