export const TRANSLATIONS = {
  "en": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "hr": {
    "wof": {
      "NoContent": "Nemate nijedan dostupan lutrijski program.",
      "NoNext": "Nemate dostupnih okretaja. Molimo provjerite <a href=\"javascript:void(0)\">Uvjeti i odredbe</a>",
      "NoHistories": "Nema povijesti",
      "Program": "Program",
      "History": "Povijest",
      "ActiveTickets": "Aktivne Karte",
      "ImplicitTickets": "Implicitne Karte",
      "RemainingTimes": "Preostalo Vrijeme",
      "Retry": "Mreža je trenutno zauzeta, molimo kliknite OK za ponovno okretanje",
      "ShowNext": "Kolo će biti dostupno<br /> <strong>{startTime}</strong> <br />Molimo pričekajte da se otvori.",
      "DrawFailed": "Dodjela nagrada možda nije uspjela, molimo kontaktirajte korisničku podršku za više detalja",
      "Timeout": "Mreža je trenutno zauzeta, molimo pokušajte kasnije.",
      "ErrorNetwork": "Niste povezani, molimo provjerite postavke mreže",
      "ErrorJSON": "Neočekivan odgovor podataka, molimo kontaktirajte korisničku podršku",
      "Congratulation": "Čestitamo! Osvojili ste {prize}!",
      "OK": "OK",
      "Rewards": "Nagrade",
      "Status": "Status",
      "Time": "Vrijeme",
      "Loss": "OOPs! Blizu ste nagrade!"
    }
  },
  "zh": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "fr": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "ro": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "es": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "pt": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "de": {
    "wof": {
      "NoContent": "You don't have any lottery programs available now.",
      "NoNext": "You don't have any spins available. Please check <a href=\"javascript:void(0)\">Terms and Conditions</a>",
      "NoHistories": "No histories",
      "Program": "Program",
      "History": "History",
      "ActiveTickets": "Active Tickets",
      "ImplicitTickets": "Implicit Tickets",
      "RemainingTimes": "Remaining Times",
      "Retry": "Network is a bit busy now, please click OK to re-spin",
      "ShowNext": "The Wheel will be available on<br /> <strong>{startTime}</strong> <br />Please wait till it is open.",
      "DrawFailed": "The prize assignment may be failed, please contact customer support for more details",
      "Timeout": "Network is a bit busy now, please try again later.",
      "ErrorNetwork": "You are offline, please check your network settings",
      "ErrorJSON": "Unexpected Data Response, please contact customer support",
      "Congratulation": "Congratulations! You won a {prize}!",
      "OK": "OK",
      "Rewards": "Rewards",
      "Status": "Status",
      "Time": "Time",
      "Loss": "OOPs! You are close to a prize!"
    }
  },
  "tr": {
    "wof": {
      "NoContent": "Şu anda herhangi bir piyango programınız yok.",
      "NoNext": "Kullanılabilir herhangi bir döndürmeniz yok. Lütfen <a href=javascript:void(0)'>Şartlar ve Koşullar</a>'ı kontrol edin",
      "NoHistories": "Geçmiş yok",
      "Program": "programı",
      "History": "Tarih",
      "ActiveTickets": "Aktif Biletler",
      "ImplicitTickets": "Örtülü Biletler",
      "RemainingTimes": "Kalan Süreler",
      "Retry": "Ağ şu anda biraz meşgul, lütfen yeniden döndürmek için Tamam'a tıklayın",
      "ShowNext": "Çark şu tarihte satışa sunulacak:<br /> <strong>{startTime}</strong> <br />Lütfen açılana kadar bekleyin.",
      "DrawFailed": "Ödül ataması başarısız olabilir, daha fazla ayrıntı için lütfen müşteri desteğiyle iletişime geçin",
      "Timeout": "Ağ şu anda biraz meşgul, lütfen daha sonra tekrar deneyin.",
      "ErrorNetwork": "Çevrimdışısınız, lütfen ağ ayarlarınızı kontrol edin",
      "ErrorJSON": "Beklenmeyen Veri Yanıtı, lütfen müşteri desteğiyle iletişime geçin",
      "Congratulation": "Tebrikler! Bir {ödül} kazandınız!",
      "OK": "TAMAM",
      "Rewards": "Ödüller",
      "Status": "Durum",
      "Time": "Zaman",
      "Loss": "Ah! Bir ödüle yaklaştınız!"
    }
  }
}