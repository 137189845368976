declare global {
  namespace svelte.JSX {
    interface SVGProps<T> {
      part?: string
    }
  }
}

export interface Point {
  x: number;
  y: number;
}

export enum PointerMode {
  Arrow     = "Arrow",
  Partition = "Partition",
}

export enum ArrowMode {
  DownFromTop      = "DownFromTop",
  UpFromCenter     = "UpFromCenter",
  DownFromCenter   = "DownFromCenter",
  UpFromBottom     = "UpFromBottom",
}

export enum Api {
  lotteries = 'lotteries',
  outcome   = 'outcome',
  draw      = 'draw',
}

export interface ApiConfigs {
  [Api.lotteries]: {
    session?: string
  },
  [Api.outcome]: {
    session?: string
    id: string
  },
  [Api.draw]: {
    session?: string
    id: string
    guid: string
  },
  
}

export enum Lang { 
  en = 'en',
  fr = 'fr',
  de = 'de',
  tr = 'tr'
}

type StringWithLang = {
  [key in Lang]: string
}

export interface Partition {
  name:   StringWithLang
  image1: StringWithLang
  image2: StringWithLang
  image3: StringWithLang
}


export enum Tab {
  Program = 'Program',
  History = 'History'
}

export enum ContentDirection {
  clockwise = 1,
  anticlockwise = -1,
  outward = 0,
}



