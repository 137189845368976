import type { Point } from "./types"

export const getSymmetricalPointFromScalar = 
  (length: number): Point => ({
    x: length,
    y: length
  })

export const getPointOnCircle = 
  (radius: number, angle: number, center: number): Point => {

    const centerPoint: Point = getSymmetricalPointFromScalar(center)

    return {
      x: centerPoint.x + radius * Math.cos(angle),
      y: centerPoint.y + radius * Math.sin(angle)
    }
  }

export const getPoint = 
  (num: number, length: number, r: number, rCenter: number): Point => {

    const arc = 360 / length;
    const psai = Math.PI / 180 * (-90 + arc * (num - 1/2))

    const rCenterPoint = getSymmetricalPointFromScalar(rCenter)
    const rPoint = getSymmetricalPointFromScalar(r)

    return {
      x: rCenterPoint.x + rPoint.x * Math.cos(psai),
      y: rCenterPoint.y + rPoint.y * Math.sin(psai)
    }
  }

export const getPointWithNext = 
  (num: number, length: number, r1: number, r2: number) => {

    const getPointx = (num: number) => getPoint(num, length, r1, r2)
    
    return {
      point: getPointx(num),
      pointNext: getPointx(num + 1)
    }
  }

export const getTrianglePointsY = 
  (height: number, isPointUp: boolean, yBase: number) => {
    return {
      y1: yBase,
      y2: isPointUp ? yBase - height : yBase + height,
      y3: yBase
    }
  }

export const getTwoPointsOfLineGradient = (radius, index, length, angle) => ({
  x1: radius,
  y1: radius,
  x2: radius * (1 + Math.cos(2 * Math.PI / length * index + angle)),
  y2: radius * (1 + Math.sin(2 * Math.PI / length * index + angle)),
})


export const pointPlus = (point1: Point, point2: Point): Point => ({
  x: point1.x + point2.x,
  y: point1.y + point2.y
})

