import { PointerMode } from "./types"

const sizeSchema1 = {
  radius    : 247,
  rRingInner: 253,
  rRingOuter: 294,
  rBg1: 300,
}

const sizeSchema2 = {
  radius    : 247,
  rRingInner: 259,
  rRingOuter: 280,
  rBg1: 286,
}
const sizeSchema3 = {
  radius    : 247,
  rRingInner: 275,
  rRingOuter: 280,
  rBg1: 286,
}

export const themes = [
  {
    size: sizeSchema1,
    pointerMode: PointerMode.Partition,
  },
  {
    size: sizeSchema2,
    pointerMode: PointerMode.Arrow,
  },
  {
    size: sizeSchema1,
    pointerMode: PointerMode.Arrow,
  },
  {
    size: sizeSchema1,
    pointerMode: PointerMode.Partition,
  },
  {
    size: sizeSchema1,
    pointerMode: PointerMode.Arrow,
  },
  {
    size: sizeSchema2,
    pointerMode: PointerMode.Arrow,
  },
  {
    size: sizeSchema3,
    pointerMode: PointerMode.Arrow,
  },
]
