import { findDeg } from './calc';
import { _postMessage } from './message';
import { Api, ApiConfigs, PointerMode } from './types';
import type { LotteryProgramForPlayer, ApiDrawResult, ApiLotteriesResult } from './types.business';
import { CurrentInfo } from './types.business';
import { fetcher } from './util';
import { _ } from './i18n';

export * from './business.partition'
import type { Option } from './business.partition'

const fetcherApi = async <T extends Api>(api: Api, endpoint: string, config: ApiConfigs[T]) => {

  const { session } = config

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'accept': 'text/plain',
      
      ...(session ? {'x-SessionId': session} : {})
    },
  }

  const getArgs = {
    [Api.lotteries]: () => {

      const {  } = config || {} as ApiConfigs[Api.lotteries]

      return {
        path: '/wof/lotteries', 
        params: {
        },
        options,
      }
    },
    [Api.outcome]: () => {
      
      const { id } = config as ApiConfigs[Api.outcome]

      return {
        path: `/wof/outcome/${id}`,
        params: {
        },
        options,
      }
    },
    [Api.draw]: () => {
      
      const { id, guid } = config as ApiConfigs[Api.draw]

      return {
        path: `/wof/draw/${id}`,
        params: {
        },
        options: {
          ...options,
          method: 'PUT',
					body: JSON.stringify({
						guid
					})
        }
      }
    }
  }

  const args = getArgs[api]()
  
  args.path = endpoint + `/v1` + args.path

  return await fetcher(args)
}

export const setMessage = (id: string, entry: string | object) => {
  const _postMessageScoped = (props) => _postMessage({ ...props, id })
  switch(typeof entry) {
    case 'string': 
      _postMessageScoped({ type: 'wof-private-message-open', mode: 'normal', modeValue: entry })
      break;
    case 'object': 
      _postMessageScoped({ type: 'wof-private-message-open', ...(entry as object)  })
      break;
  }
}

export const getCurrentInfo = (
  lotteryProgramForPlayer: LotteryProgramForPlayer, 
  key: CurrentInfo
) => {

  const { current } = lotteryProgramForPlayer

  switch (key) {
    case CurrentInfo.ActiveTickets:
      return current.activeTickets

    case CurrentInfo.ImplicitTickets:
      const { 
        maxImplicitTickets: max, 
        usedImplicitTickets: used,
      } = current

      return `${max - used}/${max}`

    case CurrentInfo.RemainingTimes:
      return current.remainingTimes
  }
}

export const api = {
  lotteries: 
    async (endpoint: string, session: string) => {

      const data = await fetcherApi(Api.lotteries, endpoint, { session }) as ApiLotteriesResult

      const lotteryProgramForPlayers = data.items as LotteryProgramForPlayer[] || []

      return lotteryProgramForPlayers
    },
  draw: 
    async (endpoint: string, session: string, id: string, guid, options: Option[]) => {

      let data = await fetcherApi(Api.draw, endpoint, { id, session, guid }) as ApiDrawResult
      
      let index = data.item?.result?.wheelOfFortunePartitionIndex
      
      const getOptionAndImageSrc = (_index: number) => {
        const option = options[_index]
        return {
          option, 
          imageSrc: (option?.image as SVGAElement)?.href?.baseVal
        } as const
      }

      let message: any
      if(!index){
        index = options.length - 1
          
        const { imageSrc } = getOptionAndImageSrc(index)
        message = {
          mode: 'nogift',
          modeValue: {
            image: imageSrc
          }
        }
      }else{
        const { imageSrc, option } = getOptionAndImageSrc(index)
        message = {
          mode: 'gift',
          modeValue: {
            prize: option.name || 'prize',
            prizeText: option.prizeText,
            prizeSrc: option.prizeImage || imageSrc
          }
        }
      }
    
      return {
        data,
        message, index
      }
    },

  outcome: 
    async (endpoint: string, session: string, id: string) => {
      return await fetcherApi(Api.outcome, endpoint, { session, id })
    },
}

export const getSpinContainerSelector = (pointerMode: PointerMode) => {
  switch(pointerMode){
    case PointerMode.Arrow: return 'g.PartitionsContainer'
    case PointerMode.Partition: return '.PointerArea > div'
  }
}

export const findDegWithPointerMode = (index: number, length: number, pointerMode: PointerMode) => {
  switch(pointerMode){
    case PointerMode.Arrow: 
      return findDeg(length - index, length) // + randomInSection(length)
    case PointerMode.Partition:
      return findDeg(index, length)
  }
}

export const isBonusInvalid = (bonus) => bonus?.current.remainingTimes == 0 && !bonus?.next