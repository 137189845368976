<svelte:options tag={'lottery-program-wof-private-tabs'} />

<script lang="ts">
  import { _postMessage } from "./message";
  import { Lang, Tab } from "./types";
  import { _ } from './i18n';
  import { setClientStyling } from "./widget";

  // properties
  export let lang: Lang = Lang.en
  export let endpoint: string = ''
  export let session: string = ''
  export let clientstyling:string = ''
  export let isshowhistory: string = 'true'
  
  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);

  let tab: Tab = Tab.Program;

  const onTabClick = (_tab) => {
    switch(_tab){
      case Tab.Program: 
        break;
      case Tab.History:
        _postMessage({type: 'wof-private-message-outcomes'})
        break;
    }

    tab = _tab
  }
</script>
{#if isshowhistory === 'true'}
<section class="TabBar" bind:this={rootContainer}>
  <ul>
    {#each Object.keys(Tab) as _tab, i}
    <li
      class:active={_tab === tab}
      class="TabItem"
      on:click={() => onTabClick(_tab)}
    >
      <span>
        {$_(`wof.${_tab}`)}
      </span>
    </li>
    {/each}
  </ul>
</section>
{/if}

{#each Object.keys(Tab) as _tab}
  {#if tab === _tab}
    <slot name={`tab-${_tab}`} />
  {/if}
{/each}


<style lang="scss">

  :host {
    font-family: system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
    width: 100%;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  .TabBar {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;

    ul {
      max-width: 600px;
      width: 100%;

      li {
        color: #FFFFFF;
        display: inline-block;
      }
    }

    ul {
      border-bottom: 3px #ccc solid;

      li {
        padding: 6px 10px;
        cursor: pointer;
        &.active {
          border-bottom: 3px #d46666 solid;
          margin-bottom: -6px;
        }
      }
    }
  }
</style>