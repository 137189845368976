import { _postMessage } from "./message"
import { setMessage } from './business';

export class Process {

  id: string
  halter: Function
  afterSuccess: Function
  afterSetMessage: Function
  fetcher: Function
  
  private guid: string
  private isDrawn: boolean = false
  private drawnIndex: number;
  private retryingTimes: number = 0
  private retryingMaxTimes: number = 4

  constructor(params){
    this.id = params.id
    this.afterSetMessage = params.afterSetMessage
    this.halter = params.halter
    this.afterSuccess = params.afterSuccess
    this.fetcher = params.fetcher
  }

  handleHaltProcess(index, message, fn: Function = () => {}){
    _postMessage({ id: this.id, type: 'wof-private-message-spin-before' })

    const cb = () => {
      setTimeout(() => {
        _postMessage({ id: this.id, type: 'wof-private-message-spin-after' })
        fn()
        this.setMessage(message)
      }, 1000)
    }

    // spinner.halt(calc.getDeg(index), cb)
    this.halter(index, cb)
  }

  getRetryingPeriod() {
    if(this.retryingTimes === 1){
      return 5 * 1000
    }else{
      return 10 * 1000
    }
  }

  drawInit() {
    this.retryingTimes = 0
    this.guid = undefined
    this.isDrawn = false
  }
  
  async drawer (guid?: string){
    if(guid) this.guid = guid
    if(!this.guid) throw new Error('GUID NOT FOUND')

    if(this.retryingTimes >= this.retryingMaxTimes){

      if(this.isDrawn){
        this.handleHaltProcess(this.drawnIndex, 'DrawFailed')
      }else{
        this.handleHaltProcess(undefined, 'Timeout')
      }

      this.drawInit()

      return;
    }
    this.retryingTimes += 1

    let isFetchCompleted = false
    let isTimeouted = false
    
    setTimeout(() => {
      if(!isFetchCompleted){
        isTimeouted = true
        this.setMessage({
          mode: 'timeout',
          modeValue: 'Retry'
        })
      }
    }, this.getRetryingPeriod())

    try {

      const { data, message, index: _index } = await this.fetcher(this.guid)
      this.drawnIndex = _index
      
      isFetchCompleted = true

      if(!data.success) throw new Error()
      switch(data.item.state) {
        case 'fulfilled':
          this.isDrawn = true
          break
        case 'drawn':
          this.isDrawn = true
          throw new Error()
      }

      if(isTimeouted) return;

      this.handleHaltProcess(this.drawnIndex, message, this.afterSuccess)
      this.drawInit()

    } catch (e) {
      if(e.message === "Failed to fetch"){
        isFetchCompleted = true
        this.handleHaltProcess(undefined, 'ErrorNetwork')
        return
      }

      if(e.message.includes("is not valid JSON")){
        isFetchCompleted = true
        this.handleHaltProcess(undefined, 'ErrorJSON')
        return
      }

      if(isTimeouted) return;
      this.drawer()
    }
  }

  setMessage(entry: string | object) {
    setMessage(this.id, entry)
    this.afterSetMessage()
  }
}