import { onMount } from "svelte"

export const _postMessage = (message) => window.postMessage(message, window.location.href);

const composeMessageHandler = (listeners) => {
  const handler = e => {
    Object.keys(listeners).map(key => {
      if (e.data && e.data.type == key) {
        const {type, ...restData} = e.data
        listeners[key](restData)
      }
    })
  }

  return handler
}

export const onMountMessageLifeCycle = (messageRegisters) => {
  
  const messageHandler = composeMessageHandler(messageRegisters)

  onMount(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    }
  })
}