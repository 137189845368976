import type { WheelOfFortunePartition } from './api'

// @ts-ignore
import Thankyou1Svg from './images/Thankyou_1.svg'
// @ts-ignore
import Thankyou2Svg from './images/Thankyou_2.svg'
// @ts-ignore
import Thankyou3Svg from './images/Thankyou_3.svg'
// @ts-ignore
import Thankyou4Svg from './images/Thankyou_4.svg'
import type { Lang } from './types'
import type { LotteryProgramForPlayer } from './types.business'
import { imageLoaderSvg, isSafari } from './util'

export interface Option {
  image: SVGElement | string,
  name: string,
  prizeText: string,
  prizeImage: string
}

const optionCompliment = {
  image: Thankyou1Svg,
  name: 'Thank you',
  prizeText: '',
  prizeImage: '',
}

const getSumProbability = (partitions) => {
  let probabilityAll = 0
  partitions.map(partition => {
    probabilityAll += Number(partition.probability)
  })
  return probabilityAll
}

const getOptionsFromPartitions = (partitions: WheelOfFortunePartition[], lang: Lang): Option[] => {
  const options = partitions.map(_partition => {
    const option = {}
    Object.keys(_partition).map((_key) => {
      if(
        typeof _partition[_key] === 'object' && 
        (_partition[_key] as Object).hasOwnProperty('*')
      ){
        const targetKey = _key === 'image1' ? 'image' : _key
        option[targetKey] = _partition[_key][lang] || _partition[_key]['*']
      }
    })

    return option as Option
  })

  if(getSumProbability(partitions) < 1){
    options.push(optionCompliment)
  }

  return options
}

const preloadImage = async (src: string) => {
  if(!src) return {}

  return { 
    image: await imageLoaderSvg(src) as SVGImageElement
  }
}

export const getOptions = async (bonus: LotteryProgramForPlayer, lang: Lang) => {

  const { partitions } = bonus.program.wheelOfFortune

  let options: Option[] = getOptionsFromPartitions(partitions, lang)

  if(isSafari()){
    options = options.map(option => {
      if(option.image){
        let img = document.createElementNS("http://www.w3.org/2000/svg", "image")
        img.href.baseVal = option.image as string
        
        return {
          ...option,
          image: img
        }
      }else{
        return option
      }
    })
  }else{
    options = await Promise.all(
      options.map(async o => ({
        ...o, 
        ...(await preloadImage(o.image as string))
      }))
    )
  }

  return options
}