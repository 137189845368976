import { getPointOnCircle, getPointWithNext, getSymmetricalPointFromScalar, getTwoPointsOfLineGradient, pointPlus } from "./calc.point"
import { ArrowMode, Point, PointerMode, ContentDirection } from "./types"
import { mapObjectValueToTuple } from "./util"

import { findDegWithPointerMode } from "./business"
import { themes } from "./themes"

enum RotateDirection {
  clockwise = 1,
  anticlockwise = -1,
}

export class SvgCalc {
  arrowmode: ArrowMode.DownFromTop
  radius
  center
  length
  ratio
  theme
  themeIndex
  options
  contentdirection: keyof typeof ContentDirection

  constructor({
    size,
    radius,
    options,
    themeIndex,
    contentdirection,
  }){
    this.contentdirection = contentdirection || 'anticlockwise'
    // size
    this.center = size / 2
    this.ratio = size / 375
    // options
    this.options = options
    this.length = options.length
    // theme
    this.themeIndex = themeIndex
    this.theme = themes[themeIndex]
    //
    this.radius     = radius
  }

  get sizeImage () {
    const getSize = (length) => this.ratio * 48 * 5 / length

    if(this.length <= 3) return getSize(4)

    switch(this.length){
      case 8: 
      case 7: return this.ratio * 24
      case 6: return this.ratio * 36
      case 5: return this.ratio * 48
      case 4: return this.ratio * 56
      default: return getSize(this.length)
    }
  }

  getSizeImageByPartition(index) {
    if(this.options[index].name){
      return this.sizeImage / 2
    }else{
      return this.sizeImage
    }
  }

  getSvgImageProps (index, partitionDivisor: string | number = 1.25){
    const sizeImage = this.getSizeImageByPartition(index)

    let baseRadius
    if(this.options[index].name){
      baseRadius = this.radius - sizeImage / 2 - 3 * this.ratio
    }else{
      baseRadius = (this.radius - sizeImage / 2) / Number(partitionDivisor) - 3 * this.ratio;
    }

    return {
      ...this.getPropsForPartitionInfo(index, this.getOffsetImage(sizeImage), baseRadius, ContentDirection.outward),
      width: sizeImage,
      height: sizeImage,
    }
  }

  getSvgTextProps(index) {
    const sizeImage = this.getSizeImageByPartition(index)

    let baseRadius
    if(this.options[index].image){
      baseRadius = this.radius - sizeImage - 6 * this.ratio
    }else{
      baseRadius = this.radius - 6 * this.ratio
    }

    return {
      ...this.getPropsForPartitionInfo(index, this.offsetText, baseRadius),
    }
  }

  getSvgTextPropsAdjustedByImage(index) {
    const props = this.getSvgTextProps(index)
    const size = this.options[index].image ? 80 : 100

    const objectSize = {
      width: size,
      height: size,
    }
    
    return {
      ...props,
      ...objectSize,
      x: this.contentdirection === 'clockwise' ? props.x : props.x - objectSize.width,
      y: props.y - objectSize.height / 2,
    }
  }
  
  get offsetText (){
    return {
      center: 0,
      position: {
        x: 0, //option.name.length * 4,
        y: 0
      },
      
      transform: {
        x: 0,
        y: 0,
      }
    }
  }

  getOffsetImage (sizeImage) {
    
    return {
      center: 0,
      position: {
        x: -1 * sizeImage / 2,
        y: -1 * sizeImage / 2,
      },
      transform: {
        x: 0,
        y: 0,
      },
    }
  }

  get direction () {
    return RotateDirection.clockwise
  }

  getAngleSelf (index, contentDirection?: ContentDirection) {
    const baseAngle = 360 * index / this.length * this.direction
    const fixerAngle = 90 * (contentDirection !== undefined ? contentDirection : ContentDirection[this.contentdirection])
    const resultAngle = baseAngle + fixerAngle
    return resultAngle
  }

  getPartitionPositions (index, baseRadius, offset) {
    
    const angle = 
      this.angleTransform(this.arrowmode) +
      this.direction * index * 2 * Math.PI / this.length

    const point = getPointOnCircle(baseRadius || this.radius - 9 * this.ratio, angle, this.center + offset.center)
    
    const transformOrigin: Point = pointPlus(point, offset.transform)
    const pointResult = pointPlus(point, offset.position)
    return {
      transformOrigin,
      point: pointResult,
    }
  }

  getPropsForPartitionInfo (
    index: number, 
    offset: any,
    baseRadius?: number,
    contentDirection?: ContentDirection
  ) {
    
    const { point, transformOrigin } = this.getPartitionPositions(index, baseRadius, offset)
    const getTransformOriginString = 
      (vector: Point) => 
        Object.keys(vector).map(axis => `${vector[axis]}px`).join(' ')
    
    return {
      ...point,
      style: [
        `font-size: ${13 * this.ratio}px`,
        `transform: rotate(${this.getAngleSelf(index, contentDirection)}deg)`,
        `transform-origin: ${getTransformOriginString(transformOrigin)}`,
      ].join(';')
    }
  }

  getPartitionDraw(
    index: number,
  ){

    const rPoint = getSymmetricalPointFromScalar(this.center)
    const rRingInnerPoint = getSymmetricalPointFromScalar(this.radius)

    const { point, pointNext } = getPointWithNext(index, this.length, this.radius, this.center)

    const ds = [
      `M`, ...mapObjectValueToTuple(rPoint),
      `L`, ...mapObjectValueToTuple(point),
      `A`, 
        ...mapObjectValueToTuple(rRingInnerPoint),
        `0 0 1`,
        ...mapObjectValueToTuple(pointNext),
      `Z`,
    ]

    return {
      d: ds.join(' ')
    }
  }

  getDeg(index?: number){
    return findDegWithPointerMode(index || this.length - 1, this.length, this.theme.pointerMode)
  }

  getSpinnerProps(){
    const centerPoint = getSymmetricalPointFromScalar(this.center)
    return {
      // transform: `rotate(${convertArcToDeg(0)})`, //arcStart
      "transform-origin": `${centerPoint.x} ${centerPoint.y}`,
    }
  }
  
  private angleTransform = (arrowmode) => {
    switch(this.theme.pointerMode){
      case PointerMode.Arrow:
        switch(arrowmode){
          case ArrowMode.DownFromTop:
          case ArrowMode.UpFromCenter:
            return - Math.PI / 2

          case ArrowMode.DownFromCenter:
          case ArrowMode.UpFromBottom: 
            return Math.PI / 2
        }

      case PointerMode.Partition:
        return - Math.PI / 2
    }
  }
}
