import {
  dictionary,
  locale,
  addMessages,
  _
} from 'svelte-i18n';

import { TRANSLATIONS } from './translations';

function setupI18n({ withLocale: _locale, translations }) {
  locale.subscribe((data) => {
    if (data == null) {
      dictionary.set(translations);
      locale.set(_locale);
    }
  }); // maybe we will need this to make sure that the i18n is set up only once
  /*dictionary.set(translations);
  locale.set(_locale);*/
}

function addNewMessages(lang, dict) {
  addMessages(lang, dict);
}

function setLocale(_locale) {
  locale.set(_locale);
}

const setLocaleWhenInit = () => {
  
  setupI18n({ withLocale: 'en', translations: {}});

  Object.keys(TRANSLATIONS).forEach((item) => {
    addNewMessages(item, TRANSLATIONS[item]);
  });
}

export { _, setupI18n, addNewMessages, setLocale, setLocaleWhenInit };