<svelte:options tag={null} />

<script lang="ts">
  import './private.message.svelte'
  import './private.tabs.svelte'
  import './private.outcomes.svelte'
  import './private.item.svelte'
  import './private.loader.svelte'

  import { onMount } from 'svelte';
  import { _, setLocale, setLocaleWhenInit } from './i18n';

  // types
  import { ContentDirection, Lang } from './types';
  import { api, isBonusInvalid } from './business';

  import { _postMessage, onMountMessageLifeCycle } from './message';
  import { CurrentInfo, LotteryProgramForPlayer } from './types.business';
  import { setClientStyling, setClientStylingURL, setTranslationUrl } from './widget';

  // properties
  export let lang: Lang = Lang.en
  export let endpoint: string = ''
  export let session: string = ''
  export let clientstyling: string = ''
  export let clientstylingurl: string = ''
  export let translationurl: string = ''
  export let programid: string = ''
  export let loginevent: string = ''
  export let loginurl: string = ''
  export let currentinfo: CurrentInfo = CurrentInfo.RemainingTimes
  export let giftimagesrc: string = ''
  export let isshowhistory: string = 'true'
  export let contentdirection: keyof typeof ContentDirection = 'anticlockwise'
  
  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);
  $: clientstylingurl && rootContainer && setClientStylingURL(clientstylingurl, (_clientstyling) => clientstyling = _clientstyling);
  $: translationurl && setTranslationUrl(translationurl);

  $: commonProps = {
    endpoint,
    lang,
    session,
    clientstyling,
  }

  let isMounted = false
  $: isMounted && init()
  onMount(() => {
    isMounted = true
    return () => isMounted = false
  })

  setLocaleWhenInit()

  let bonuses: LotteryProgramForPlayer[] = []
  let loading: boolean = true;

  $: hasSession = session && session !== 'false'

  const init = async () => {
    loading = true
    if(!hasSession) {
      loginAction(loginevent, loginurl)
      return;
    }
    
    if(!isMounted || !lang || !session || !endpoint) return;
    
    loading = true
    await fetcher()
    loading = false
  }

  const fetcher = async () => {
    const _bonuses = await api.lotteries(endpoint, session)
    
    _postMessage({ type: 'wof-private-bonuses-outcomes', bonuses: _bonuses })

    bonuses = 
      _bonuses
        .map(_bonus => {
          if(!isBonusInvalid(_bonus)){
            return _bonus
          }
        })
        .filter(_bonus => _bonus)
    
    _postMessage({ type: 'WofStatus', avaliable: bonuses.length > 0 })
    _postMessage({ type: 'wof-private-bonuses', bonuses })
  }

  const loginAction = (loginevent, loginurl) => {
    if (loginevent) {
      window.postMessage({ type: loginevent, transition: 'Login' }, window.location.href);
    }
    
    if (loginurl) {
      window.location = loginurl;
    }
  }

  const setLangReactive = () => {
    setLocale(lang)
    init()
  }

  onMountMessageLifeCycle({
    'wof-private-checking': (data) => {
      if(isBonusInvalid(data.bonus)){
        bonuses = bonuses.map(_bonus => {
          if(_bonus.program.id === data.id){
            return {..._bonus, hidden: true}
          }else{
            return _bonus
          }
        })
      }

      let hidden = true
      bonuses.map(_bonus => {
        if(_bonus.hidden !== true){
          hidden = false
        }
      })

      if(hidden){
        bonuses = []
      }
    }
  })

  $: session && init()
  $: endpoint && init()
  $: lang && setLangReactive()

</script>

<div class="WofContainer" bind:this={rootContainer}>
  <lottery-program-wof-private-tabs {...commonProps} {isshowhistory}>
    <div slot="tab-Program">
      <div class="CommonContainer">
        {#if loading}
          <wof-loader />
        {:else}
          {#if bonuses.length}
            {#each bonuses as bonus}
              <lottery-program-wof-private-item {...{
                ...commonProps,
                id: bonus.program.id,
                currentinfo,
                giftimagesrc,
                contentdirection,
              }} />
            {/each}
          {:else}
            <div class="NoContent">
              <p>{$_('wof.NoContent')}</p>
            </div>
          {/if}
        {/if}
      </div>
    </div>
    <div slot="tab-History">
      <lottery-program-wof-private-outcomes {...{
        ...commonProps,
        id: bonuses.find(_bonus => _bonus.program.id === programid)?.program.id,
      }} />
    </div>
  </lottery-program-wof-private-tabs>
</div>


<style lang="scss">

  lottery-program-wof-private-item {
    display: inline-block;
  }

  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    outline: none;
    box-sizing: border-box;
  }

  .WofContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 0;
    background: var(--emfe-w-color-contrast, #07072A);
  }

  .NoContent {
    color: rgb(255, 255, 255);
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  
  .CommonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>