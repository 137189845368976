<svelte:options tag={'lottery-program-wof-private-item-svg'} />

<script lang="ts">

  // types
  import { PointerMode, Lang, ContentDirection } from './types';
  import { getOptions, getSpinContainerSelector, Option } from './business';
  import { api } from './business';
  import { onMountMessageLifeCycle, _postMessage } from './message';
  import type { LotteryProgramForPlayer } from './types.business';
  import { themes } from './themes';
  import { isSafari, setProps } from './util';
  import { Spinner } from './class.spinner';
  import { SvgCalc } from './class.svgcalc';
  import { Process } from './class.process';
  import { _ } from './i18n';
  import { setClientStyling } from './widget';
  import { defines } from './themes.image.center';

  // properties common
  export let lang: Lang = Lang.en
  export let endpoint: string = ''
  export let session: string = ''
  export let clientstyling:string = ''
  export let contentdirection: keyof typeof ContentDirection = 'anticlockwise'
  export let partitiondivisor: string = ''

  let rootContainer: HTMLElement
  $: clientstyling && rootContainer && setClientStyling(rootContainer, clientstyling);

  // properties
  export let id: string = undefined
  export let size: string = ''
  export let radius: string = ''

  let isPartitionsCustomableReady: boolean = false
  // temp way to make sure transform-origin effects on safari
  const handleSafariIssue = (trigger) => {
    trigger(-1)
    setTimeout(() => {
      trigger(1)
      isPartitionsCustomableReady = true
    }, 100)
  }

  let bonus: LotteryProgramForPlayer
  const updateOptions = async () => {
    if(!options.length){
      options = await getOptions(bonus, lang)

      _postMessage({ type: 'wof-private-options-ready', id })

      const trigger = (amount: number) => size = String(Number(size) + amount)
      handleSafariIssue(trigger)
    }
  }
  $: bonus && lang && updateOptions()

  let options: Option[] = []

  // binds
  let svg: SVGElement;
  let spinContainer

  let spinner = new Spinner({
    tick: (deg, _speed) => {
      setProps(spinContainer, {
        style: [
          `transform: rotate(${deg}deg)`,
          `height: ${size}px`,
          `width: ${size}px`,
        ].join(';'), //mix-blend-mode: screen; background-blend-mode: screen;
      })
      speed = _speed * 0.5
    }
  })

  // states
  let spinable = true;
  let isSpinning = false;
  let isShowPrizeArea = false;
  let messageShown: boolean = false;
  let shownFirstCheck = false

  let speed: number = 0

  $: themeIndex = bonus?.program?.metadata?.template || 0
  $: theme = themes[themeIndex] || themes[0]
  $: calc = new SvgCalc({
    size,
    radius,
    options,
    themeIndex,
    contentdirection,
  })
  $: options && updateCalcOptions()
  $: (contentdirection || !contentdirection) && updateCalcContentDirection()

  $: bonus && runFirstCheck()

  const updateCalcOptions = () => {
    calc.options = options
  }
  const updateCalcContentDirection = () => {
    calc.contentdirection = contentdirection || 'anticlockwise'
    setImageProps()
  }

  const setImageProps = () => {
    if(!rootContainer) return;

    for ( let index = 0; index <= options.length; index++ ) {
      const image = rootContainer.querySelector(`.PartitionImage.PartitionImage${index} image`)
      if(image) {
        setProps(image, calc.getSvgImageProps(index, partitiondivisor))
      }
    }
  }

  const runFirstCheck = () => {
    if(shownFirstCheck) return;
    shownFirstCheck = true
    updateSpinable()
  }

  const updateSpinable = () => {
    const isSpinable = !!bonus.current && bonus.current.remainingTimes > 0

    if(isSpinable){
      spinable = true
    }else{
      spinable = false;

      if(bonus.next){
        process.setMessage({
          mode: 'show-next',
          modeValue: bonus.next
        })
      }else{
        process.setMessage({
          mode: 'NoNext',
        })
      }
    }
  }

  $: process = new Process({
    id,
    afterSetMessage: () => messageShown = true,
    halter: (index, cb) => {
      const targetIndex = index >= 0 ? index : options.length - 1
      spinner.halt(calc.getDeg(targetIndex), cb)
    },
    afterSuccess: () => isShowPrizeArea = true,
    fetcher: async (guid) => await api.draw(endpoint, session, id, guid, options)
  })

  // events
  const eventSpin = async () => {
    updateSpinable();
    if(!spinable) return;
    if(isSpinning) return;

    isSpinning = true
    isShowPrizeArea = false

    spinContainer = svg.querySelector(getSpinContainerSelector(theme.pointerMode))

    spinner.launch()
    process.drawer(`userid-${id}-${new Date().getTime()}`)
  }
  onMountMessageLifeCycle({
    'wof-private-bonuses': (data) => {
      bonus = data.bonuses.find(_bonus => _bonus.program.id === id)
    },
    'wof-private-bonus': (data) => {
      if(data.id !== id) return;
      bonus = data.bonus
    },
    'wof-private-message-close': (data) => {
      if(data.id !== id) return;

      isSpinning = false
      setTimeout(() => updateSpinable(), 1)
      messageShown = false
    },
    'wof-private-message-retry': (data) => {
      if(data.id !== id) return;

      messageShown = false
      process.drawer()
    }
  })

  const renderImage = (node: SVGElement, index) => {
    const image = options[index].image as SVGImageElement
    setProps(image, calc.getSvgImageProps(index))
    node.innerHTML = null
    node.appendChild(image)
  }


  $: optionFilter = theme.pointerMode === PointerMode.Arrow && speed > 0.3 ? `blur(${speed}px)` : null
  $: sizeProps = { height: size, width: size}
  $: foreignObjectAgentProps = {
    style: Object.keys(sizeProps).map(key => `${key}: ${sizeProps[key]}px`).join(';')
  } 
</script>

<div
  class={`WheelContainer theme${Number(themeIndex) + 1}`}
  bind:this={rootContainer}
  style={`${defines()} --length: ${options.length}; --radius: ${radius}; --ratio: ${Number(size) / 480}; --size: ${size}`}
>
  {#if Number(size) && options.length}
  <svg
    bind:this={svg}
    width={size}
    height={size}
    on:click={() => isSafari() && eventSpin()}
    style:opacity={messageShown ? '.3': ''}
  >
    <foreignObject {...sizeProps} class="Bottom Customable">
      <div {...foreignObjectAgentProps} />
    </foreignObject>

    <g class="PartitionsContainer" {...calc.getSpinnerProps()}>
      <g class="PartitionsBackgrounds">
        {#each options as option, index}
          <foreignObject
            clip-path={`url(#clip${index})`}
            class="PartitionBackground Customable"
            style={`--index: ${index}`}
            width={size}
            height={size}
            >
            <div {...foreignObjectAgentProps} />
          </foreignObject>
        {/each}
      </g>
      <g class="PartitionsBackgroundStrokes">
        {#each options as option, index}
        <path
          class="PartitionBackgroundStroke"
          {...calc.getPartitionDraw(index)}
          width={size}
          height={size}
        />
        {/each}
      </g>

      <g class="Partitions" style:filter={optionFilter}>
        {#each options as option,index}

          {#if option.image}
            <g
              class={`PartitionImage PartitionImage${index}`}
              use:renderImage={index}
            />
          {/if}

          {#if option.name}
            <foreignObject
              class="PartitionText"
              {...calc.getSvgTextPropsAdjustedByImage(index)}
            >
              <div class="PartitionTextEntityContainer">
                <p class={`PartitionTextEntity${calc.contentdirection === 'clockwise' ? '' : ' Anticlockwise'}`}>{@html option.name}</p>
              </div>
            </foreignObject>
          {/if}

        {/each}
      </g>

    </g>

    <g class="PartitionsCustomable1" class:active={isPartitionsCustomableReady}>
      {#each options as option,index}
      <foreignObject
        class="Partition1 Customable"
        style={`--index: ${index}`}
        {...sizeProps}
        >
        <div {...foreignObjectAgentProps} />
      </foreignObject>
      {/each}
    </g>
    <g class="PartitionsCustomable2" class:active={isPartitionsCustomableReady}>
      {#each options as option,index}
      <foreignObject
        class="Partition2 Customable"
        style={`--index: ${index}`}
        {...sizeProps}
        >
        <div {...foreignObjectAgentProps} />
      </foreignObject>
      {/each}
    </g>

    {#if theme.pointerMode === PointerMode.Partition}
    <g class="PointerContainer" {...calc.getSpinnerProps()}>

      <foreignObject class="PointerArea Customable" {...sizeProps}>
        <div {...foreignObjectAgentProps} />
      </foreignObject>

      <path
        class="PointerPartitionFrame"
        class:active={isShowPrizeArea}
        {...calc.getPartitionDraw(0)}
      />
    </g>
    {/if}

    <foreignObject class="Middle Customable" {...sizeProps}>
      <div {...foreignObjectAgentProps} />
    </foreignObject>
    <foreignObject class="Top Customable" {...sizeProps}>
      <div {...foreignObjectAgentProps} />
    </foreignObject>

    <g
      class="Center"
      class:spinning={isSpinning}
      on:click={() => isSafari() || eventSpin()}
    >
      <foreignObject
        x={Number(size)/2 - 100/2}
        y={Number(size)/2 - 100/2}
        width={100}
        height={100}
      />
    </g>

    <!-- defs -->
    <g>
      {#each options as option, index}
      <clipPath {...{id: `clip${index}`}}>
        <path {...calc.getPartitionDraw(index)} />
      </clipPath>
      {/each}
    </g>
  </svg>
  {/if}
</div>

<style lang="scss">
  @import './private.item.svg.scss';

  g.Center {
    &.spinning {
      cursor: not-allowed;
    }
  }
</style>
