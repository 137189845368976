// @ts-nocheck
import themeBgHalo from './images/theme.bg.halo.svg'
import themeBgBlackRing from './images/theme.bg.blackring.svg'
import themeCenter1 from './images/themeCenter1.svg'
import themeCenter2 from './images/themeCenter2.svg'
import themePartitionLight from './images/themePartitionLight.svg'
import themePointerArea from './images/themePointerArea.svg'

import theme1Bg3 from './images/theme1Bg3.svg'
import theme2Bg3 from './images/theme2Bg3.svg'
import theme2Arrow from './images/theme2Arrow.svg'

import theme3Bg1 from './images/theme3Bg1.svg'
import theme3Bg2 from './images/theme3Bg2.svg'
import theme3Arrow from './images/theme3Arrow.svg'

import theme7Bg1 from './images/theme7Bg1.svg'
import theme7Bg2 from './images/theme7Bg2.svg'
import theme7Bg3 from './images/theme7Bg3.svg'

import theme2Center from './images/theme2Center.svg'
import theme3Center from './images/theme3Center.svg'
import theme7Center from './images/theme7Center.svg'
import theme7Light  from './images/theme7Light.svg'
import theme7Arrow from './images/theme7Arrow.svg'

import themeShadow from './images/themeShadow.svg'

const defineBottom = () => `
  --img-theme-BgBlackRing: url("${themeBgBlackRing}");
  --img-theme-BgHalo: url("${themeBgHalo}");
  --img-theme1-bg3: url("${theme1Bg3}");
  --img-theme2-bg3: url("${theme2Bg3}");
  --img-theme3-bg2: url("${theme3Bg2}");
  --img-theme3-bg1: url("${theme3Bg1}");

  --img-theme7-bg3: url("${theme7Bg3}");
  --img-theme7-bg2: url("${theme7Bg2}");
  --img-theme7-bg1: url("${theme7Bg1}");

`

const defineMiddle = () => `
  --img-theme-center1: url("${themeCenter1}");
  --img-theme2-center: url("${theme2Center}");
  --img-theme2-arrow: url("${theme2Arrow}");
  --img-theme3-center: url("${theme3Center}");
  --img-theme3-arrow: url("${theme3Arrow}");
  --img-theme7-arrow: url("${theme7Arrow}");
  --img-theme-center2: url("${themeCenter2}");
  --img-theme7-center: url("${theme7Center}");
`

const defineTop = () => `
  --img-theme-shadow: url("${themeShadow}");
  --img-theme7-light: url("${theme7Light}");
`
 
export const defines = () => 
  [
    defineBottom(),
    defineMiddle(),
    defineTop(),
    `  
      --img-theme-partition-light: url("${themePartitionLight}");
      --img-theme-pointer-area: url("${themePointerArea}");
    `
  ].join(' ')
