
export enum SpinStep {
  launch = 'launch',
  loop = 'loop',
  halt = 'halt',
}

export enum Easing {
  quad  = 'quad',
  cubic = 'cubic',
  quart = 'quart',
  quint = 'quint',
  // 
  expo = 'expo',
  sine = 'sine',
  circ = 'circ',
}

export const easing = {
  in: {
    quad:       (x: number) => Math.pow(x, 2),
    cubic:      (x: number) => Math.pow(x, 3),
    quart:      (x: number) => Math.pow(x, 4),
    quint:      (x: number) => Math.pow(x, 5),
    //
    sine:       (x: number) => 1 - Math.cos((x * Math.PI) / 2),
    expo:       (x: number) => x === 0 ? 0 : Math.pow(2, 10 * x - 10),
    circ:       (x: number) => 1 - Math.sqrt(1 - Math.pow(x, 2)),
  },
  out: {
    quad:      (x: number) => 1 - Math.pow(1 - x, 2),
    cubic:     (x: number) => 1 - Math.pow(1 - x, 3),
    quart:     (x: number) => 1 - Math.pow(1 - x, 4),
    quint:     (x: number) => 1 - Math.pow(1 - x, 5),
    //
    sine:      (x: number) => Math.sin((x * Math.PI) / 2),
    expo:      (x: number) => x === 1 ? 1 : 1 - Math.pow(2, -10 * x),
    circ:      (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2)),
  }
}

export const easingGrad = {
  quad:  2,
  cubic: 3,
  quart: 4,
  quint: 5,
  sine:  Math.PI / 2,
  expo:  Math.LN2,
  circ:  Infinity,
}

export const easingGradX = {
  in: {
    quad:  (x: number) => 2 * x,
    cubic: (x: number) => 3 * x ** 2,
    quart: (x: number) => 4 * x ** 3,
    quint: (x: number) => 5 * x ** 4,
    sine:  (x: number) => Math.PI / 2 * Math.sin(Math.PI / 2 * x),
    expo:  (x: number) => Math.LN2 * 10 * 2 ** (10 * x - 10),
    circ:  (x: number) => -1 / 2 * (1 - x ** 2) ** (- 1 / 2),
  },
  out: {
    cubic: (x: number) => 3 * x ** 2 - 6 * x + 3,
  }
}

export const params = [
  // basic
  {
    launchTurns: 5,
    launchSpeed: 0.2,
    loopSpeed: 15,
    haltTurns: 2,
    haltSpeed: 0.16,
  },
  // faster
  {
    launchTurns: 2,
    launchSpeed: 0.5,
    loopSpeed: 20,
    haltTurns: 2,
    haltSpeed: 0.22,
  },
  // faster with more turns
  {
    launchTurns: 6,
    launchSpeed: 1.38,
    loopSpeed: 45,
    haltTurns: 6,
    haltSpeed: 0.50,
  },
]